import themeConfig from "@/themeConfig.js"
import env from '../env.js'
import countries from './db/countries.js'
import { getUserData } from '@/auth/utils'

document.title = env.documentTitle;
// ////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
    AppActiveUser: getUserData(),
    layout: {
        layoutType: themeConfig.layoutType,
        layoutWidth: themeConfig.layoutWidth,
        leftSidebarType: themeConfig.leftSidebarType,
        leftSidebarSize: themeConfig.leftSidebarSize,
        menuPosition: themeConfig.menuPosition,
        topbar: themeConfig.topbar,
        loader: themeConfig.loader,
        contentPageFixed: themeConfig.contentPageFixed
    },
    domain: env.domain,
    localUserKey: env.localUserKey,
    appName: env.appName,
    windowWidth: null,
    countries: countries,
    coupons : [],
}

export default state
