import Vue from 'vue'
import Router from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [
        {
        // =============================================================================
        // MAIN LAYOUT ROUTES
        // =============================================================================
            path: '',
            redirect: '/',
            component: () => import('./layouts/Main.vue'),
            children: [
                {
                    path: '/',
                    name: 'dashboard',
                    component: () => import('@views/Dashboard.vue'),
                    meta: {
                      breadcrumb: [

                        {
                          text: "Dashboard",
                          active: true
                        }
                      ],
                      pageTitle: 'Welcome!',
                      authRequired: true,
                    }
                },
                {
                    path: '/account',
                    name: 'account',
                    component: () => import('@views/account/UserEdit.vue'),
                    meta: {
                       breadcrumb: [
                          {
                            text: "Dashboard",
                            to:{ path:"/"}
                          },
                          {
                            text: "Account",
                            active: true
                          }
                      ],
                      pageTitle: 'Profile',
                      authRequired: true,
                    },
                },
                {
                    path: '/orders',
                    name: 'order',
                    component: () => import('@views/orders/OrderList.vue'),
                    meta: {
                      breadcrumb: [
                          {
                            text: "Dashboard",
                            to:{ path:"/"}
                          },
                          {
                            text: "Orders",
                            active: true
                          }
                      ],
                      pageTitle: "Orders List",
                      authRequired: true,
                    },
                },
                {
                  path: '/orders/:orderId(\\d+)',
                  name: 'order-view',
                  component: () => import('@views/orders/show/OrderShow.vue'),
                  meta: {
                      breadcrumb: [
                        {
                          text: "Orders",
                          to:{ path:"/orders"}
                        },
                        {
                          text: "Show",
                          active: true
                        }
                    ],
                    pageTitle: "View Order",
                    authRequired: true,
                  },
                },
                {
                    path: '/products',
                    name: 'products',
                    component: () => import('@views/products/ProductList.vue'),
                    meta: {
                    breadcrumb: [
                        {
                        text: "Dashboard",
                        to: { path:"/"}
                        },
                        {
                        text: "Products",
                        active: true
                        }
                    ],
                    pageTitle: "Products List",
                    authRequired: true,
                    },
                },
                {
                    path: '/products/:productId(\\d+)',
                    name: 'products-view',
                    component: () => import('@views/products/show/ProductShow.vue'),
                    meta: {
                    breadcrumb: [
                        {
                            text: "Products",
                            to: { path:"/products"}
                        },
                        {
                            text: "Show",
                            active: true
                        }
                    ],
                    pageTitle: "View Product",
                    authRequired: true,
                    },
                },
                {
                    path: '/users',
                    name: 'users-list',
                    component: () => import('@views/users/UserList.vue'),
                    meta: {
                      breadcrumb: [
                          {
                            text: "Dashboard",
                            to: { path:"/"}
                          },
                          {
                            text: "User List",
                            active: true
                          }
                      ],
                      pageTitle: "Users List",
                      authRequired: true,
                    },
                },
                {
                    path: '/users/create',
                    name: 'users-create',
                    component: () => import('@views/users/add/UserCreate.vue'),
                    meta: {
                      breadcrumb: [
                          {
                            text: "Dashboard",
                            to: { path:"/"}
                          },
                          {
                            text: "User",
                            active: true
                          }
                      ],
                      pageTitle: "Create User",
                      authRequired: true,
                    },
                },
                {
                  path: '/users/:userId(\\d+)',
                  name: 'user-view',
                  redirect: to => {
                    return { path: `/users/${to.params.userId}/edit` }
                  },
                  meta: {
                    pageTitle: "Vew User",
                    authRequired: true,
                  },
                },
                {
                    path: '/users/:userId(\\d+)/edit',
                    name: 'user-edit',
                    component: () => import('@views/users/edit/UserEdit.vue'),
                    meta: {
                      breadcrumb: [
                          {
                            text: "Dashboard",
                            to: { path:"/"}
                          },
                          {
                            text: "Users",
                            to: { path:"/users"}
                          },
                          {
                            text: "Edit User",
                            active: true
                          }
                      ],
                      pageTitle: "Vew User",
                      authRequired: true,

                    },
                },
                {
                    path: '/payments',
                    name: 'payments',
                    component: () => import('@views/payments/Payments.vue'),
                    meta: {
                        breadcrumb: [
                          {
                            text: "Dashboard",
                            to: { path:"/"}
                          },
                          {
                            text: "Payments List",
                            active: true
                          }
                      ],
                      pageTitle: "Payments",
                      authRequired: true,
                    }
                },
                {
                    path: '/notifications',
                    name: 'notifications',
                    component: () => import('@views/notification/Notifications.vue'),
                    meta: {
                        breadcrumb: [
                          {
                            text: "Dashboard",
                            to:{ path:"/"}
                          },
                          {
                            text: "Notifications List",
                            active: true
                          }
                      ],
                      pageTitle: "Notifications",
                      no_scroll: true,
                      authRequired: true,
                    }
                },
                {
                  path: '/marketing',
                  name: 'marketing',
                  component: () => import('@views/marketing/Marketing.vue'),
                  meta: {
                    breadcrumb: [
                        {
                          text: "Dashboard",
                          to: { path:"/"}
                        },
                        {
                          text: "Marketing Mails",
                          active: true
                        }
                    ],
                    pageTitle: "Marketing Mails",
                    authRequired: true,
                  },
                },
                {
                    path: '/settings/coupons',
                    name: 'coupons',
                    component: () => import('@views/settings/coupons/Coupons.vue'),
                    meta: {
                        breadcrumb: [
                          {
                            text: "Dashboard",
                            to:{ path:"/"}
                          },
                          {
                            text: "Coupon List",
                            active: true
                          }
                      ],
                      pageTitle: "Coupons",
                      no_scroll: true,
                      authRequired: true,
                    }
                },
            ]
        },
        // =============================================================================
        // FULL PAGE LAYOUTS
        // =============================================================================
        {
            path: '',
            component: () => import('@/layouts/FullPage.vue'),
            children: [
                {
                    path: '/login',
                    name: 'login',
                    component: () => import('@/views/pages/Login.vue'),
                    meta: {
                        guestRequired: true,
                    }
                },
                {
                    path: '/error-404',
                    name: 'error-404',
                    component: () => import('@/views/pages/Error404.vue'),
                },
                {
                    path: '/error-500',
                    name: 'error-500',
                    component: () => import('@/views/pages/Error500.vue'),
                },
                {
                    path: '/not-authorized',
                    name: 'not-authorized',
                    component: () => import('@/views/pages/NotAuthorized.vue'),
                },
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/error-404'
        },

    ],
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('appplicationBusyPage')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})

router.beforeEach((to, from, next) => {
    const isLoggedIn = isUserLoggedIn()
    if(to.meta.authRequired) {
      if (!isLoggedIn) return next({path: '/login' });
    }
    if(to.meta.guestRequired) {
      if (isLoggedIn) return next({path: '/' });
    }
    return next();
});
export default router


