
import axios from "@/axios.js"

export default {
  fetchProducts({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/products")
        .then((response) => {
          if(response.data.success){
            commit('SET_PRODUCTS', response.data.products)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchProduct({ commit }, productId) {
    return new Promise((resolve, reject) => {
      axios.get(`/products/${productId}/show`)
        .then((response) => {
          if(response.data.success){
               commit('SET_PRODUCT', response.data.product)
            }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
