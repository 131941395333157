
import state from './moduleUserListState.js'
import mutations from './moduleUserListMutations.js'
import actions from './moduleUserListActions.js'
import getters from './moduleUserListGetters.js'

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

