
import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

import moduleNotification from "./notification/moduleNotification.js"
import moduleProductList from "./product-list/moduleProductList.js"
import moduleOrderList from './order-list/moduleOrderList.js'
import moduleUserList from './user-list/moduleUserList.js'

Vue.use(Vuex)

export default new Vuex.Store({
    state,
    actions,
    getters,
    mutations,
    modules: {
        notification: moduleNotification,
        orderList: moduleOrderList,
        productList: moduleProductList,
        userList: moduleUserList,
    },
    strict: process.env.NODE_ENV !== 'production'
})
