

export default {
  SET_ORDERS(state, orders) {
    state.orders = orders
  },
  SET_ORDER(state, order){
  	state.order = order
    if(state.orders.length){
      const dataIndex = state.orders.findIndex((o) => o.id == order.id);
      Object.assign(state.orders[dataIndex], order);
    }
  },
  DELETE_ORDER(state, orderId){
        const itemIndex = state.orders.findIndex((item) => item.id == orderId)
        state.orders.splice(itemIndex, 1)
  },


  /**************** THIS IS FOR MESSAGES********************/
  SET_MESSAGE_SEARCH_QUERY(state, query) {
    state.messageSearchQuery = query
  },
  SET_MESSAGES(state, messages){
    state.order.messages = [...messages]
  },
  ADD_MESSAGE(state, message){
    state.order.messages.push(message)
  },
  UPDATE_MESSAGE(state, message){
    const dataIndex = state.order.messages.findIndex((a) => a.id == message.id);
    Object.assign(state.order.messages[dataIndex], message);
  },
  DELETE_MESSAGE(state, messageId){
    const itemIndex = state.order.messages.findIndex((item) => item.id == messageId)
      state.order.messages.splice(itemIndex, 1)
  },
}
