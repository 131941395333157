

export default {
  ADD_USER(state, user) {
    state.users.push(user)
  },
  SET_USERS(state, users) {
    state.users = [...users]
  },
  SET_ROLES(state, roles) {
    state.roles = roles
  },
  SET_USER(state, user) {
    state.user = {...user}
    if(state.users.length){
        const dataIndex = state.users.findIndex((u) => u.id == user.id);
        Object.assign(state.users[dataIndex], user);
    }
  },
  DELETE_USER(state, userId) {
      state.user = {}
      const userIndex = state.users.findIndex((u) => u.id == userId)
      state.users.splice(userIndex, 1)
  },
  SET_USER_CHATS(state, chats) {
    state.chats = [...chats]
  },
  ADD_USER_CHAT(state, chat) {
    state.chats.push(chat)
  },
  UPDATE_USER_CHAT(state, chat){
    const dataIndex = state.chats.findIndex((f) => f.id == chat.id)
    Object.assign(state.chats[dataIndex], chat)
  },
  DELETE_USER_CHAT(state, chatId){
    const ItemIndex = state.chats.findIndex((item) => item.id == chatId)
    state.chats.splice(ItemIndex, 1);
  },
  SET_CHAT_SEARCH_QUERY(state, query) {
    state.chatSearchQuery = query
  },
  MARK_SEEN_ALL_CHATS(state, payload) {
    payload.chatData.forEach((msg) => {
      msg.is_read = 1
    })
  },
}
