export default {
    SET_PRODUCTS(state, products) {
      state.products = products
    },
    SET_PRODUCT(state, product) {
      state.product = product
      if(state.products.length){
        const dataIndex = state.products.findIndex((o) => o.id == product.id);
        Object.assign(state.products[dataIndex], product);
      }
    },
    DELETE_PRODUCT(state, productId) {
        const ItemIndex = state.products.findIndex((item) => item.id == productId)
        state.products.splice(ItemIndex, 1)
    },

    /************** FOR DESCRIPTION *****************/
    UPDATE_DESCRIPTION(state, description){
      state.product.description = description
    },

    /************** FOR PACKAGE *****************/
    SET_PACKAGES(state, packages){
      state.product.product_packages = [...packages]
    },

    /************** FOR ADDONS *****************/
    ADD_ADDON(state, addon){
      state.product.addons.unshift(addon);
    },
    SET_ADDONS(state, addons){
      state.product.addons = [...addons];
    },
    UPDATE_ADDON(state, addon){
      const dataIndex = state.product.addons.findIndex((f) => f.id == addon.id)
       Object.assign(state.product.addons[dataIndex], addon)
    },
    DELETE_ADDON(state, addonId){
      const ItemIndex = state.product.addons.findIndex((item) => item.id == addonId)
      state.product.addons.splice(ItemIndex, 1);
    },

    /************** FOR QUESTIONS *****************/
    ADD_QUESTION(state, question){
      state.product.questions.unshift(question);
    },
    SET_QUESTIONS(state, questions){
      state.product.questions = [...questions];
    },
    UPDATE_QUESTION(state, question){
      const dataIndex = state.product.questions.findIndex((f) => f.id == question.id)
       Object.assign(state.product.questions[dataIndex], question)
    },
    DELETE_QUESTION(state, questionId){
      const ItemIndex = state.product.questions.findIndex((item) => item.id == questionId)
      state.product.questions.splice(ItemIndex, 1);
    },

    /************** FOR ATTRIBUTES *****************/
    ADD_ATTRIBUTE(state, attribute){
      state.product.attributes.unshift(attribute);
    },
    SET_ATTRIBUTES(state, attributes){
      state.product.attributes = [...attributes];
    },
    UPDATE_ATTRIBUTE(state, attribute){
      const dataIndex = state.product.attributes.findIndex((f) => f.id == attribute.id)
        Object.assign(state.product.attributes[dataIndex], attribute)
    },
    DELETE_ATTRIBUTE(state, attributeId){
      const ItemIndex = state.product.attributes.findIndex((item) => item.id == attributeId)
      state.product.attributes.splice(ItemIndex, 1);
    },

    /************** FOR PORTFOLIOS *****************/
    ADD_PORTFOLIO(state, portfolio){
      state.product.portfolios.unshift(portfolio);
    },
    SET_PORTFOLIOS(state, portfolios){
      state.product.portfolios = [...portfolios];
    },
    UPDATE_PORTFOLIO(state, portfolio){
      const dataIndex = state.product.portfolios.findIndex((f) => f.id == portfolio.id)
       Object.assign(state.product.portfolios[dataIndex], portfolio)
    },
    DELETE_PORTFOLIO(state, portfolioId){
      const ItemIndex = state.product.portfolios.findIndex((item) => item.id == portfolioId)
      state.product.portfolios.splice(ItemIndex, 1);
    }
  }
