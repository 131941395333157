import axios from "@/axios.js"
import env from '@/env.js'


function autoLogin() {
  const get_localUser = localStorage.getItem(env.localUserKey);
  if(get_localUser){
    const storedData =  JSON.parse(unescape(atob(get_localUser)));
    const expirationTime = new Date(storedData.tokenExpirationDate);
    if (expirationTime <= new Date()) {
      localStorage.removeItem(env.localUserKey);
    }else{
      axios.defaults.headers.Authorization = "Bearer " +storedData.token;
      autoLogout(expirationTime.getTime() - new Date().getTime());
    }
  }else{
    localStorage.removeItem(env.localUserKey);
  }
}

function autoLogout(duration) {
  if(logout_time){
    clearInterval(timeout);
  }
  var logout_time = setTimeout(() => {
    axios.defaults.headers.Authorization = "";
    localStorage.removeItem(env.localUserKey);
    window.location = "/login";
  }, duration);
}
autoLogin();

