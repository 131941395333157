export default {
  computed: {
    getStatusColor(){
      return (status) => status ? "primary" : "warning"
    }
  },
  methods: {
    dropzoneTemplate: function () {
        return ` <div class="dropzone-previews mt-3">
        <div class="card mt-1 mb-0 shadow-none border">
            <div class="p-2">
                <div class="row align-items-center">
                    <div class="col-auto">
                        <img data-dz-thumbnail src="#" class="avatar-sm rounded bg-light" alt="">
                    </div>
                    <div class="col pl-0">
                        <a href="javascript:void(0);" class="text-muted font-weight-bold" data-dz-name></a>
                        <p class="mb-0" data-dz-size></p>
                    </div>
                    <div class="col-auto">
                        <!-- Button -->
                        <a href="" class="btn btn-link btn-lg text-muted" data-dz-remove>
                            <i class="fe-x"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `;
    },
    pageloader(action){
        let loader = document.getElementById('applicationBusyPage');
        if(loader){
          action == "stop" ? loader.style.display = "none" : loader.style.display = "flex";
        }
    },
    reviewFormart(reviews){
      if(!reviews){ reviews = []}
      let total = reviews.length
      let five = reviews.filter(r => r.rating == 5).length
      let four = reviews.filter(r => r.rating == 4).length
      let three = reviews.filter(r => r.rating == 3).length
      let two = reviews.filter(r => r.rating == 2).length
      let one = reviews.filter(r => r.rating == 1).length
      let average = total ? Math.round(((five*5+four*4+three*3+two*2+one*1)/total) * 10)/10 : 0;
      return `${average} (${total})`;
    },
    getExtension(filename) {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    },
    // isImage(filename) {
    //     var ext = this.getExtension(filename);
    //     switch (ext.toLowerCase()) {
    //         case 'jpg':
    //         case 'gif':
    //         case 'bmp':
    //         case 'png':
    //         //etc
    //         return true;
    //     }
    //     return false;
    // },
    // isVideo(filename) {
    //     var ext = this.getExtension(filename);
    //     switch (ext.toLowerCase()) {
    //         case 'm4v':
    //         case 'avi':
    //         case 'mpg':
    //         case 'mp4':
    //         // etc
    //         return true;
    //     }
    //     return false;
    // },
    calculateDeliveryTime(time){
      if(!time){return}
      return (time > 24) ? Math.round(time/24) + ' days' : '1 day'
    },
    scrollToReview(elID){
        document.getElementById(elID).scrollIntoView({behavior: "smooth"})
    },
    alertSuccess(text){
        toastr.success(text, 'Success');
    },
    alertError(text){
        toastr.error(text, 'Something went worry', {timeOut: 3000});
    },
  }
};
